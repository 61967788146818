/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://baitffuj5ndo7j4oca7luve2um.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_LAMBDA",
    "aws_appsync_apiKey": "da2-yf6lfqifpbbfpo3hui5qn7qze4",
    "aws_cognito_identity_pool_id": "us-east-1:705840ca-9c3c-4372-ad93-7d388aa7e976",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_V59nMdBR7",
    "aws_user_pools_web_client_id": "34a1kpojv6opqa003m8dul4dlj",
    "oauth": {
        "domain": "annwebappde20ee8b-de20ee8b-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://dev.d2v27wdqmwozgs.amplifyapp.com/",
        "redirectSignOut": "http://localhost:3000/,https://dev.d2v27wdqmwozgs.amplifyapp.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
