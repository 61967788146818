import React, { useEffect, useRef } from 'react';
import { useStore } from "../../../../hooks";
import { observer } from "mobx-react";
import { TemplateContainer } from "./Marketing.styled";
import TemplateEditor from "../../../../elements/v2/TemplateEditor/TemplateEditor";
import { EmailTemplateWrapper } from "../../BlastCampaigns/BlastCampaignConfiguration.styled";
import { SuccessfulModal } from '../../../../elements';
import {useSearchParams} from "react-router-dom";

const Message = observer(() => {
  const { authStore, templateEditorStore, marketingStore, contactSegmentStore } = useStore();
  const [searchParams] = useSearchParams();

  const initialLoadRef = useRef(false);
  const emailEditorRef = useRef();

  useEffect(() => {
    templateEditorStore.updateGeneralField("customer", authStore.selectedAccount.id);
    templateEditorStore.updateGeneralField("s3FileRoute", "email_templates");
    templateEditorStore.updateGeneralField("to", "{{To}}");
  }, []);

  // Load initial template content
  useEffect(() => {
    const loadInitialContent = async () => {
      try {
        if (!initialLoadRef.current && templateEditorStore.templateId) {
          const emailPayload = marketingStore.getEmailPayload();
          const bodyField = emailPayload.find(item => item.field === "body");
          
          if (bodyField?.value) {
            const content = await marketingStore.getEmailTemplateContent(bodyField.value);
            if (content && !marketingStore.mainEmailContent) {
              marketingStore.setMainEmailContent(content);
            }
            initialLoadRef.current = true;
          }
        }
      } catch (error) {
        // Keeping error handling but removing console.error
      }
    };

    void loadInitialContent();
  }, [templateEditorStore.templateId, marketingStore]);

  // Handle template editor initialization
  useEffect(() => {
    if (templateEditorStore.templateId) {
      templateEditorStore.loadTemplate();
    }

    return () => {
      templateEditorStore.updateGeneralField("isLoading", false);
    };
  }, [templateEditorStore.templateId]);

  useEffect(() => {
      marketingStore.setEmailTemplateExportAction(onExportEditor);
  }, [marketingStore]);

  const onExportEditor = async () => {
    try {
      // upload files
      await templateEditorStore.saveTemplate(templateEditorStore.mainEmailContent, marketingStore.currentDesign);

      // First save to backend
      marketingStore.updateBindingsAndPayload(
        templateEditorStore.payload.payload,
        templateEditorStore.payload.binding,
      );

      await marketingStore.save(
        authStore.selectedAccount.id,
        contactSegmentStore.advancedFilters,
        contactSegmentStore.totalRowCount
      );

      // Reset loading states
      templateEditorStore.updateGeneralField("isLoading", false);
      templateEditorStore.updateGeneralField("initialLoading", false);

    } catch (error) {
      marketingStore.setError(`Error saving template:', ${error}`);
    }
  };

  const onHandleSave = async (data) => {
    const { html, design, variables, generalData } = data;

    const { from, sender_name, to, subject, cc, bcc, description } = generalData;

    marketingStore.setCurrentDesign(design);
    marketingStore.setMainEmailContent(html);

    templateEditorStore.updateGeneralField("from", from);
    templateEditorStore.updateGeneralField("sender_name", sender_name);
    templateEditorStore.updateGeneralField("to", to);
    templateEditorStore.updateGeneralField("subject", subject);
    templateEditorStore.updateGeneralField("cc", cc);
    templateEditorStore.updateGeneralField("bcc", bcc);
    templateEditorStore.updateGeneralField("description", description);
    templateEditorStore.updateGeneralField("variables", variables);

    templateEditorStore.savePayload();
  };

  const onSearchImagesStock = async (search) => {
    await templateEditorStore.getVehicleStockImages(search);

    if (!templateEditorStore.vehicleStockImages.vehicles) {
      return {
        stockImages: [],
        total: 0,
        hasMore: false,
      }
    }

    const total = templateEditorStore.vehicleStockImagesTotalRowCount;
    const hasMore = total > search.page * search.page_size;

    return {
      stockImages: templateEditorStore.vehicleStockImages.vehicles,
      total,
      hasMore,
    }
  }

  return (
    <TemplateContainer>
      <EmailTemplateWrapper>
        <TemplateEditor
          ref={emailEditorRef}
          key={`main-message-${templateEditorStore.templateId}-${!!marketingStore.mainEmailContent}`}
          isPopup={false}
          isLoading={templateEditorStore.isLoading}
          initialContent={marketingStore.mainEmailContent}
          templateJson={templateEditorStore.templateJson}
          initialGeneralData={{
            from: templateEditorStore.from,
            sender_name: templateEditorStore.sender_name,
            to: templateEditorStore.to,
            subject: templateEditorStore.subject,
            cc: templateEditorStore.cc,
            bcc: templateEditorStore.bcc,
            description: templateEditorStore.description,
          }}
          initialVariables={templateEditorStore.variables}
          onContentChange={onHandleSave}
          onImageStock={onSearchImagesStock}
        />
      </EmailTemplateWrapper>
      {marketingStore.isError && (
        <SuccessfulModal
          isOpen={marketingStore.isError}
          onClose={() => marketingStore.clearError()}
          title="Error"
          subtitle={marketingStore.errorMessage}
          subtitle2="Please try again later."
        />
      )}
    </TemplateContainer>
  );
});

export default Message;
