import React, { useState, useEffect, useRef } from "react";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton, SuccessfulModal } from "../../../../elements";
import TemplateEditor from "../../../../elements/v2/TemplateEditor/TemplateEditor";
import { useStore } from "../../../../hooks";
import { observer } from "mobx-react";
import {
  RetryContainer,
  RetryHeader,
  RetryRow,
  LabelWrapper,
  InputWrapper,
  NumberInputWrapper,
  ActionsWrapper,
  AddButtonWrapper,
  TimeInputWrapper,
  UnitSelectWrapper,
} from "./RetryComponent.styled";
import CircularProgress from '@mui/material/CircularProgress';
import { Select, MenuItem } from "@mui/material";

const TIME_UNITS = [
  { value: 'minutes', label: 'Minutes' },
  { value: 'hours', label: 'Hours' },
  { value: 'days', label: 'Days' },
  { value: 'months', label: 'Months' },
  { value: 'years', label: 'Years' }
];

const RetryComponent = observer(() => {
  const { marketingStore, templateEditorStore } = useStore();
  const [retries, setRetries] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentRetryIndex, setCurrentRetryIndex] = useState(null);
  const [expandedEditorIndex, setExpandedEditorIndex] = useState(null);
  const emailEditorRef = useRef(null);
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [isSavingContent, setIsSavingContent] = useState(false);
  const saveTimeoutRef = useRef(null);
  const pendingSaveRef = useRef(null);

  useEffect(() => {
    const initializeRetries = async () => {
      try {
        if (marketingStore.retries && marketingStore.retries.length > 0) {
          const newRetries = marketingStore.retries.map((retry) => {
            const versionKey = Object.keys(retry)[0];

            const retryData = retry[versionKey];

            if (!retryData.generalData) {
              retryData.generalData = {
                from: '',
                sender_name: '',
                to: '',
                subject: '',
                cc: '',
                bcc: '',
                description: '',
              };
            }

            if (!retryData.variables) {
              retryData.variables = [];
            }

            retry[versionKey] = retryData;
            return retry;
          });

          setRetries(newRetries);
        } else {
          const emailPayload = marketingStore.getEmailPayload();
          const bodyField = emailPayload.find(item => item.field === "body");
          
          if (bodyField?.value) {
            const response = await marketingStore.getEmailTemplateContent(bodyField.value);
            const initialRetries = [{
              v1: {
                unit: "days",
                value: 1,
                message: response,
                htmlContent: response,
                tempContent: response,
                design: {
                  counters: {
                    u_row: 1,
                    u_column: 1,
                    u_content_text: 1
                  },
                  body: {
                    id: "yQAqQ_FWq9",
                    rows: [{
                      id: "IMqxW6ljY1",
                      cells: [1],
                      columns: [{
                        id: "GhpTn5zlrL",
                        contents: [{
                          id: "tqEY6ULQ4p",
                          type: "text",
                          values: {
                            text: response
                          }
                        }]
                      }],
                      values: {
                        backgroundColor: "",
                        columnsBackgroundColor: "",
                        backgroundImage: {
                          url: "",
                          fullWidth: true,
                          repeat: false,
                          center: true,
                          cover: false
                        }
                      }
                    }],
                    values: {
                      backgroundColor: "#ffffff",
                      width: "600px",
                      padding: "20px"
                    }
                  }
                },
                generalData: {
                  from: '',
                  sender_name: '',
                  to: '',
                  subject: '',
                  cc: '',
                  bcc: '',
                  description: '',
                },
                variables: []
              },
            }];
            setRetries(initialRetries);
            marketingStore.setRetries(initialRetries);
          }
        }
      } catch (error) {
        marketingStore.setError(`Error initializing retries: ${error}`);
      }
    };

    initializeRetries();
  }, [marketingStore.marketingConversation]);

  const logRetries = (updatedRetries) => {
    marketingStore.setRetries(updatedRetries);
    marketingStore.updateMarketingConversation({
      ...marketingStore.marketingConversation,
      retries: updatedRetries
    });
  };

  const addRetry = async () => {
    if (retries.length < 10) {
      const version = `v${retries.length + 1}`;
      const emailPayload = marketingStore.getEmailPayload();
      const bodyField = emailPayload.find(item => item.field === "body");
      
      try {
        const templateContent = bodyField?.value ? 
          await marketingStore.getEmailTemplateContent(bodyField.value) : "";

        const updatedRetries = [
          ...retries,
          {
            [version]: {
              unit: "days",
              value: 1,
              message: templateContent,
            },
          },
        ];
        setRetries(updatedRetries);
        logRetries(updatedRetries);
      } catch (error) {
        marketingStore.setError(`Error adding retry: ${error}`);
        setIsModalOpen(false);
      }
    }
  };

  const removeRetry = (index) => {
    const updatedRetries = retries.filter((_, i) => i !== index);
    setRetries(updatedRetries);
    logRetries(updatedRetries);
  };

  const updateRetry = (index, key, value) => {
    const updatedRetries = retries.map((retry, i) => {
      if (i === index) {
        const versionKey = Object.keys(retry)[0];
        return {
          [versionKey]: { ...retry[versionKey], [key]: value },
        };
      }
      return retry;
    });
    setRetries(updatedRetries);
    logRetries(updatedRetries);
  };

  const handleDaysChange = (index, increment) => {
    const updatedRetries = retries.map((retry, i) => {
      if (i === index) {
        const versionKey = Object.keys(retry)[0];
        return {
          [versionKey]: {
            ...retry[versionKey],
            value: Math.min(10, Math.max(1, retry[versionKey].value + increment)),
          },
        };
      }
      return retry;
    });
    setRetries(updatedRetries);
    logRetries(updatedRetries);
  };

  const handleBodyChange = (index, value) => {
    const updatedRetries = retries.map((retry, i) => {
      if (i === index) {
        const versionKey = Object.keys(retry)[0];
        return {
          [versionKey]: { ...retry[versionKey], message: value },
        };
      }
      return retry;
    });
    setRetries(updatedRetries);
    logRetries(updatedRetries);
  };

  const onHandleEditAdditionInfo = (index, field, data) => {
    const tempRetries = retries;

    const versionKey = Object.keys(tempRetries[index])[0];
    tempRetries[index][versionKey][field] = data;

    setRetries(tempRetries);
  }

  const handleEditorClose = async (index) => {
    const unlayer = emailEditorRef.current?.editor;
    if (unlayer) {
      unlayer.exportHtml((data) => {
        const { html } = data;
        if (html) {
          // Store in MarketingStore
          marketingStore.setTemporaryRetryEdit(index, html);
          
          // Update local state
          const versionKey = Object.keys(retries[index])[0];
          const updatedRetries = retries.map((retry, i) => {
            if (i === index) {
              return {
                [versionKey]: { 
                  ...retry[versionKey],
                  tempContent: html,
                  isModified: true
                },
              };
            }
            return retry;
          });
          setRetries(updatedRetries);
          logRetries(updatedRetries);
        }
      });
    }
    setExpandedEditorIndex(null);
    setIsEditorReady(false);
  };

  const openTemplateEditor = async (index) => {
    try {
      if (expandedEditorIndex === index) {
        handleEditorClose(index);
        return;
      }

      setCurrentRetryIndex(index);
      const versionKey = Object.keys(retries[index])[0];
      const retryData = retries[index][versionKey];

      // Always check for temporary content first
      const temporaryContent = marketingStore.getTemporaryRetryEdit(index);

      if (temporaryContent) {
        const updatedRetries = retries.map((retry, i) => {
          if (i === index) {
            return {
              [versionKey]: { 
                ...retry[versionKey],
                tempContent: temporaryContent,
              },
            };
          }
          return retry;
        });
        setRetries(updatedRetries);
        setExpandedEditorIndex(index);
        return;
      }

      // If no temporary content, get it from the API
      const response = await marketingStore.getCampaignRetryTemplate({
        message: retryData.message,
        unit: retryData.unit,
        value: retryData.value,
        customer_id: marketingStore.marketingConversation.customer,
        campaign_id: marketingStore.marketingConversation.id
      });

      if (!response?.body) {
        throw new Error('No content received from API');
      }

      const updatedRetries = retries.map((retry, i) => {
        if (i === index) {
          return {
            [versionKey]: { 
              ...retry[versionKey],
              tempContent: response.body,
            },
          };
        }
        return retry;
      });
      
      setRetries(updatedRetries);
      setExpandedEditorIndex(index);

    } catch (error) {
      marketingStore.setError(`Error in openTemplateEditor: ${error}`);
    }
  };

  const handleTemplateError = (error) => {
    if (error.response?.status === 404) {
      marketingStore.setError('Template file not found. Please check if the template exists and try again.');
    } else if (error.message.includes('HTTP error')) {
      marketingStore.setError('Error accessing template URL. The link might have expired.');
    } else {
      marketingStore.setError('Could not load template content. Please try again or contact support.');
    }
    setIsModalOpen(false);
  };

  const saveTemplateFromEditor = async (htmlContent) => {
    if (currentRetryIndex !== null) {
      try {
        
        // Get design from editor
        let design = null;
        if (emailEditorRef.current?.editor) {
          design = await emailEditorRef.current.editor.saveDesign();
        }

        // Save template with unique retry ID
        const result = await marketingStore.saveRetryTemplate({
          content: htmlContent,
          design,
          retryIndex: currentRetryIndex,
          campaignId: marketingStore.marketingConversation.id
        });

        // Update the retries state with new template info
        const versionKey = Object.keys(retries[currentRetryIndex])[0];
        const updatedRetries = retries.map((retry, i) => {
          if (i === currentRetryIndex) {
            return {
              [versionKey]: { 
                ...retry[versionKey],
                templateId: result.templateId,
                message: result.htmlFileName,
                tempContent: htmlContent,
                design: result.jsonFileName
              },
            };
          }
          return retry;
        });

        setRetries(updatedRetries);
        logRetries(updatedRetries);
        setExpandedEditorIndex(null);

        marketingStore.setMarketingAttribute("successfulAlert", {
          isOpen: true,
          title: "Success",
          message: "Retry template modified successfully"
        });

      } catch (error) {
        marketingStore.setError(`Error modifying retry template: ${error}`);
      } finally {
        marketingStore.setMarketingAttribute("isGeneratingRetry", false);
      }
    }
  };

  const updateRetryMessageFromStore = async (index) => {
    try {
      const versionKey = Object.keys(retries[index])[0];
      const currentRetry = retries[index][versionKey];

      const response = await marketingStore.getCampaignRetryTemplate({
        message: currentRetry.message,
        unit: currentRetry.unit,
        value: currentRetry.value,
        customer_id: marketingStore.marketingConversation.customer,
        campaign_id: marketingStore.marketingConversation.id
      });

      // Store both the path and HTML content from response
      const updatedRetries = retries.map((retry, i) => {
        if (i === index) {
          const versionKey = Object.keys(retry)[0];
          return {
            [versionKey]: { 
              ...retry[versionKey], 
              message: response.body, // Store the path
              tempContent: response.body, // Store the HTML content directly
              isApiContent: true // Flag to indicate this is direct API content
            },
          };
        }
        return retry;
      });
      setRetries(updatedRetries);
      logRetries(updatedRetries);
    } catch (error) {
      marketingStore.setError(`Error updating retry message: ${error}`);
    }
  };

  const handleUnitChange = (index, selectedOption) => {
    updateRetry(index, "unit", selectedOption.value);
  };

  const closeErrorModal = () => {
    marketingStore.clearError();
  };

  const handleEditorReady = (index) => {
    setIsEditorReady(true);
    const retryData = retries[index]?.[`v${index + 1}`];
    
    if (retryData && emailEditorRef.current?.editor) {
      const designToLoad = {
        counters: {
          u_row: 1,
          u_column: 1,
          u_content_text: 1
        },
        body: {
          id: "yQAqQ_FWq9",
          rows: [{
            id: "IMqxW6ljY1",
            cells: [1],
            columns: [{
              id: "GhpTn5zlrL",
              contents: [{
                id: "tqEY6ULQ4p",
                type: "text",
                values: {
                  text: retryData.htmlContent || retryData.tempContent || retryData.message || ''
                }
              }]
            }],
            values: {
              backgroundColor: "",
              columnsBackgroundColor: "",
              backgroundImage: {
                url: "",
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false
              }
            }
          }],
          values: {
            backgroundColor: "#ffffff",
            width: "600px",
            padding: "20px"
          }
        }
      };
      
      emailEditorRef.current.editor.loadDesign(designToLoad);
    }
  };

  const handleContentChange = async (index, content) => {
    if (!content) return;

    // Store the pending content
    pendingSaveRef.current = {
      index,
      content
    };

    // Clear any existing timeout
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }

    // Set saving state if not already set
    setIsSavingContent(true);

    // Set new timeout for saving
    saveTimeoutRef.current = setTimeout(async () => {
      try {
        const pendingSave = pendingSaveRef.current;
        if (!pendingSave) return;

        // Store in MarketingStore
        marketingStore.setTemporaryRetryEdit(pendingSave.index, pendingSave.content);
        
        // Update local state
        const versionKey = Object.keys(retries[pendingSave.index])[0];
        const updatedRetries = retries.map((retry, i) => {
          if (i === pendingSave.index) {
            return {
              [versionKey]: { 
                ...retry[versionKey],
                tempContent: pendingSave.content,
                isModified: true
              },
            };
          }
          return retry;
        });

        setRetries(updatedRetries);
        logRetries(updatedRetries);

        // Clear the pending save
        pendingSaveRef.current = null;
      } catch (error) {
        marketingStore.setError(`Error saving content: ${error}`);
      } finally {
        // Only hide saving indicator if no pending saves
        if (!pendingSaveRef.current) {
          setIsSavingContent(false);
        }
      }
    }, 1000); // Wait 1 second after last change
  };

  const onSearchImagesStock = async (search) => {
    await templateEditorStore.getVehicleStockImages(search);

    if (!templateEditorStore.vehicleStockImages.vehicles) {
      return {
        stockImages: [],
        total: 0,
        hasMore: false,
      }
    }

    const total = templateEditorStore.vehicleStockImagesTotalRowCount;
    const hasMore = total > search.page * search.page_size;

    return {
      stockImages: templateEditorStore.vehicleStockImages.vehicles,
      total,
      hasMore,
    }
  }

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }
    };
  }, []);

  return (
    <RetryContainer>
      {retries.map((retry, index) => {
        const versionKey = Object.keys(retry)[0];
        const retryData = retry[versionKey];
        return (
          <div key={index}>
            <RetryHeader>{`Retry ${index + 1}`}</RetryHeader>
            <RetryRow>
              <InputWrapper>
                <LabelWrapper>
                  <label>Delivery Schedule</label>
                  <IconButton icon={"iconInformation"} height="16px" width="16px" />
                </LabelWrapper>
                <TimeInputWrapper>
                  <NumberInputWrapper>
                    <input
                      type="number"
                      value={retryData.value}
                      onChange={(e) => updateRetry(index, "value", Number(e.target.value))}
                      min={1}
                      max={10}
                    />
                    <div className="arrows">
                      <KeyboardArrowUpIcon onClick={() => handleDaysChange(index, 1)} />
                      <KeyboardArrowDownIcon onClick={() => handleDaysChange(index, -1)} />
                    </div>
                  </NumberInputWrapper>
                  <UnitSelectWrapper>
                    <Select
                      value={retryData.unit}
                      onChange={(e) => handleUnitChange(index, { value: e.target.value })}
                      size="small"
                      fullWidth
                    >
                      {TIME_UNITS.map((unit) => (
                        <MenuItem key={unit.value} value={unit.value}>
                          {unit.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </UnitSelectWrapper>
                </TimeInputWrapper>
              </InputWrapper>
              <InputWrapper>
                <LabelWrapper>
                  <label>Message</label>
                  {marketingStore.isGeneratingRetry && currentRetryIndex === index && (
                    <CircularProgress size={16} />
                  )}
                </LabelWrapper>
                <textarea
                  value={retryData.tempContent}
                  onChange={(e) => handleBodyChange(index, e.target.value)}
                  rows={4}
                />
              </InputWrapper>
              <ActionsWrapper>
                <IconButton
                  icon={"iconLess"}
                  height="25px"
                  width="25px"
                  onClick={() => removeRetry(index)}
                  title="Delete retry"
                />
                <IconButton
                  icon={"iconUpdate"}
                  height="25px"
                  width="25px"
                  onClick={() => updateRetryMessageFromStore(index)}
                  title="Update retry with template from store"
                />
                <IconButton
                  icon={"iconAdd"}
                  height="25px"
                  width="25px"
                  onClick={() => openTemplateEditor(index)}
                  title={expandedEditorIndex === index ? "Close Editor" : "Edit retry in Template Editor"}
                />
              </ActionsWrapper>
            </RetryRow>
            {expandedEditorIndex === index && (
              <div style={{ marginTop: '1rem', border: '1px solid #e0e0e0', borderRadius: '4px', position: 'relative' }}>
                {isSavingContent && (
                  <div style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    borderRadius: '4px',
                    padding: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    zIndex: 1,
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    transition: 'opacity 0.3s ease-in-out', // Smooth transition
                    opacity: isSavingContent ? 0.8 : 0
                  }}>
                    <CircularProgress size={16} />
                    <span style={{ fontSize: '12px', color: '#666' }}>Saving...</span>
                  </div>
                )}
                <TemplateEditor
                  ref={emailEditorRef}
                  isOpen={true}
                  onClose={() => handleEditorClose(index)}
                  isPopup={false}
                  initialContent={retryData.tempContent || retryData.htmlContent || retryData.message || ''}
                  initialGeneralData={retryData.generalData}
                  initialVariables={retryData.variables}
                  skipTemplateLoad={true}
                  onContentChange={async (content) => {
                    if (content) {
                      const { html, generalData, variables } = content;
                      onHandleEditAdditionInfo(index, 'generalData', generalData);
                      onHandleEditAdditionInfo(index, 'variables', variables);
                      await handleContentChange(index, html);
                    }
                  }}
                  onImageStock={onSearchImagesStock}
                  key={`editor-${index}-${isEditorReady}`}
                />
              </div>
            )}
          </div>
        );
      })}

      <AddButtonWrapper>
        <button onClick={addRetry} disabled={retries.length >= 10}>
          <AddIcon />
          New
        </button>
      </AddButtonWrapper>

      {isModalOpen && !marketingStore.isError && (
        <SuccessfulModal
          isOpen={marketingStore.isError}
          onClose={closeErrorModal}
          title="Error"
          subtitle={marketingStore.errorMessage}
          subtitle2="Please try again later."
        />
      )}
      {marketingStore.isGeneratingRetry && (
        <div style={{ 
          position: 'fixed', 
          top: 0, 
          left: 0, 
          right: 0, 
          bottom: 0, 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000 
        }}>
          <CircularProgress />
        </div>
      )}
    </RetryContainer>
  );
});

export default RetryComponent;
