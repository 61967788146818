import { action, makeObservable, observable, flow } from "mobx";
import { API } from "aws-amplify";
import { getContactsSegment, getEvent, getUploadUrlFile } from "../../graphql/queries";
import _ from "lodash";
import RulesStore from "./pages/RulesStore";
import Papa, { parse } from 'papaparse';
import axios from "axios";

class ContactSegmentStore {
    

    defaultFileFields = [];
    defaultSystemFields = [];
    dynamicColumns = [];
    isLoading = false;
    account = "";
    error = "";
    rulesStore = new RulesStore();
    contacts = [];
    totalRowCount = 0;
    advancedFilters = [];

    fileUploaded = null;
    fileUploadedHeaders = [];
    fileHeadersMapping = {
        first_name: "",
        last_name: "",
        email: "",
        company_name: "",
        address: "",
        city: "",
        state: "",
        postal_code: "",
        phone: ""
    };
    parsedContactList = [];
    dynamicParsedContactList = [];
    emailTemplateEventSourceList = [{name: 'contacts', origin: 'contacts', fields: []}];
    fileId = "";
    s3FileRoute = "contact_list";
    finalFixedMappings = {};

	constructor() {
        makeObservable(this, {
            defaultFileFields: observable,
            defaultSystemFields: observable,
            setDefaultSystemFields: action,
            setDefaultFileFields: action,
            setIndividualDefaultFileFields: action,
            dynamicParsedContactList: observable,
            dynamicColumns: observable,
            account: observable,
            isLoading: observable,
            error: observable,
            rulesStore: observable,
            contacts: observable,
            totalRowCount: observable,
            advancedFilters: observable,
            fileUploaded: observable,
            fileUploadedHeaders: observable,
            fileHeadersMapping: observable,
            parsedContactList: observable,
            emailTemplateEventSourceList: observable,
            fileId: observable,
            setFileId: observable,
            s3FileRoute: observable,
            finalFixedMappings: observable,
            clear: action,
            init: action,
            updateAdvancedFilter: action,
            onClearError: action,
            setHeaderMapping: action,
            setFileUploaded: action,
            updateGeneralField: action,
            getContacts: flow,
            uploadFileToS3: flow,
            getEventsCatalog: flow,
            setDynamicColumns: action,
            setDynamicParsedContactList: action,
        });
    }

    setIndividualDefaultFileFields(fileFieldName, fileFieldValue, fileFieldIndex) {
        this.fileHeadersMapping[fileFieldName] = fileFieldValue;

        const fileFieldByIndex = this.defaultFileFields.findIndex((fileField) => fileField.index === fileFieldIndex);
        const newDynamicFileField = {
            mapping: fileFieldName,
            value: fileFieldValue,
            index: fileFieldIndex
        };

        if (fileFieldByIndex > -1) {
            this.defaultFileFields[fileFieldByIndex] = newDynamicFileField;
        }
        else if (fileFieldByIndex === -1) {
            this.defaultFileFields.push(newDynamicFileField);
        }
    }

    setHeaderMapping() {
        const newFileUploadedHeader = this.defaultFileFields.reduce((fileField, fileHeader) => {
            const currentSystemField = this.defaultSystemFields.find(
              (systemField) => systemField.index === fileHeader.index
            );

            // Only include fields that have data in the mapping
            if (currentSystemField?.value && fileHeader?.value) {
                const hasData = this.parsedContactList.some(contact => {
                    const value = contact[fileHeader.value];
                    return value !== undefined && value !== null && value.toString().trim() !== '';
                });

                if (hasData) {
                    fileField[currentSystemField.value] = fileHeader.value;
                }
            }

            return fileField;
        }, {});

        const newParsedContactList = this.parsedContactList.map((parsedContact) => {
            const newContact = {};
            Object.keys(newFileUploadedHeader).forEach((key) => {
                const fileField = newFileUploadedHeader[key];
                newContact[key] = parsedContact[fileField];
            });
            return newContact;
        });

        this.emailTemplateEventSourceList = [{ name: 'contacts', origin: 'contacts', fields: [] }];

        for (const header of Object.keys(newFileUploadedHeader)) {
            this.emailTemplateEventSourceList[0].fields.push({ name: newFileUploadedHeader[header], field: header })
        }

        this.finalFixedMappings = newFileUploadedHeader;
        this.setDynamicParsedContactList(newParsedContactList);
    }

    setDefaultFileFields(value) {
        this.defaultFileFields = value;
    }

    setDefaultSystemFields(value) {
        this.defaultSystemFields = value;
    }

    setDynamicColumns(columnsBulk) {
        this.dynamicColumns = columnsBulk;
    }

    setFileUploaded(file) {
        this.fileUploaded = file;

        if(!file) return;

        Papa.parse(file, {
            header: true,
            complete: (result) => {
                if(!result.data.length) {
                    this.fileUploaded = null;
                    return this.error = 'Uploaded CSV must contain at least one record.';
                }

                // Filter out columns that don't have any data
                const columnsWithData = result.meta.fields.filter(field => {
                    return result.data.some(row => {
                        const value = row[field];
                        return value !== undefined && value !== null && value.toString().trim() !== '';
                    });
                });

                // Update headers only with columns that have data
                this.fileUploadedHeaders = columnsWithData.map(field => ({
                    name: field,
                    value: field
                }));

                this.parsedContactList = result.data;
            }
        });
    }

    updateGeneralField(field, value) {
        this[field] = value;
    }

    *getEventsCatalog() {
        this.isLoading = true;
        try {
            const response = yield API.graphql({
                query: getEvent,
                variables: { input: JSON.stringify({}) },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            const eventsCatalog = JSON.parse(response.data?.getEvent?.body);
            this.eventsCatalog = eventsCatalog;
            this.rulesStore.setFields(eventsCatalog);
        } catch (error) {
            this.error = "Failed to fetch filters catalog";
        } finally {
            this.isLoading = false;
        }
    }

    setDynamicParsedContactList(value) {
        this.dynamicParsedContactList = value;
    }

    onClearError() {
        this.error = '';
    }

    updateAdvancedFilter() {
        const savedFilters = this.rulesStore.saveRules();
        for(const filterGroup of savedFilters) {
            for(const filter of filterGroup.conditions) {
                if(!filter['type'] || !filter['source_data'] || !filter['source_field'] || !filter['field_type'] ||
                  !filter['operator'] || !filter['target_type'] ||  !filter['target_value']) {
                    return this.error = 'One or more filters are incomplete';
                }
            }
        }
        this.advancedFilters = savedFilters;
    }

    *getContacts({ pageIndex, pageSize, sortBy, search="" }) {
        try {
            this.isLoading = true;
            const parameters = {
                count_records: false,
                page_size: pageSize || 10,
                page: (pageIndex || 0) + 1,
                order_by: !sortBy[0].id ? null : JSON.stringify(sortBy[0]),
                filters: JSON.stringify(this.advancedFilters),
                search: search.trim()
            };

            if (parameters.page === 1) {
                parameters.count_records = true;
            }

            const response = yield API.graphql({
                query: getContactsSegment,
                variables: {
                    input: {
                        customer: this.account,
                        parameters,
                    }
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS',
            });

            const body = JSON.parse(response.data["getContactsSegment"].body);
            const contacts = JSON.parse(body.contacts);

            this.contacts = contacts;

            const totalRowCount = parameters.count_records ? (body.totalRowCount || contacts.length) : this.totalRowCount;
            this.totalRowCount = totalRowCount;
        } catch (error) {
            this.error = "Contacts couldn't be fetched.";
        } finally {
            this.isLoading = false;
        }
    }


    init(account) {
        this.account = account;
    }

    setFileId(id) {
        this.fileId = id;
    }

    *uploadFileToS3(){
        if(!this.fileUploaded) return this.error = 'A file must be uploaded.';
        if(!Object.keys(this.finalFixedMappings).includes('email')) return this.error = 'Email field is required to be mapped.';
        try {     
            this.isLoading = true;
            this.parsedContactList = this.dynamicParsedContactList.map(contact => ({
                first_name: contact['first_name'] || '',
                last_name: contact['last_name'] || '',
                email: contact['email'] || '',
                company_name: contact['company_name'] || '',
                address: contact['address'] || '',
                city: contact['city'] || '',
                state: contact['state'] || '',
                postal_code: contact['postal_code'] || '',
                phone: contact['phone'] || '',
            }));

            const type = 'application/json';
            const blobFile = new Blob([JSON.stringify(this.parsedContactList, null, 2)], {type});
            const file_name = `${this.fileId}.json`;

            const response = yield API.graphql({
                query: getUploadUrlFile,
                variables: {
                    input: {
                        customer: this.account,
                        file_name,
                        file_type: type,
                        file_route: this.s3FileRoute,
                        overwrite: true
                    },
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });

            const uploadUrl = JSON.parse(response.data.getUploadUrlFile?.body);
            const config = {
                headers: {
                    "Content-Type": type,
                },
            };

            yield axios.put(uploadUrl, blobFile, config);
        } catch {
            this.error = "Error uploading CSV file";
        } finally {
            this.isLoading = false;
        }
    
      }


    clear() {
        this.isLoading = false;
        this.error = "";
        this.rulesStore = new RulesStore();
        this.fileUploaded = null;
        this.fileUploadedHeaders = [];
        this.fileHeadersMapping = {
            first_name: "",
            last_name: "",
            email: "",
            company_name: "",
            address: "",
            city: "",
            state: "",
            postal_code: "",
            phone: ""
        };
        this.parsedContactList = [];
        this.emailTemplateEventSourceList = [{name: 'contacts', origin: 'contacts', fields: []}];
        this.fileId = "";
        this.s3FileRoute = "contact_list";
        this.finalFixedMappings = {};
        this.setDefaultSystemFields([]);
        this.setDefaultFileFields([]);
        this.setDynamicParsedContactList([]);
    }
    

}

export default ContactSegmentStore;