import InputText from '../TextInput/TextInput';
import TextArea from '../TextArea/TextArea';
import { Subtitle, InputWrapper, TabContainer } from './TemplateEditor.styled';
import { useEffect, useState } from 'react';

const GeneralTab = ({ initialData, onChange }) => {
    const [from, setFrom] = useState();
    const [senderName, setSenderName] = useState();
    const [to, setTo] = useState();
    const [subject, setSubject] = useState();
    const [cc, setCc] = useState();
    const [bcc, setBcc] = useState();
    const [description, setDescription] = useState();

  useEffect(() => {
    if (initialData) {
      setFrom(initialData.from);
      setSenderName(initialData.sender_name);
      setTo(initialData.to);
      setSubject(initialData.subject);
      setCc(initialData.cc);
      setBcc(initialData.bcc);
      setDescription(initialData.description);
    }
  }, [initialData]);

    const updateFromField = (e) => {
      setFrom(e.target.value);
      onChange?.('from', e.target.value);
    }

    const updateSenderNameField = (e) => {
      setSenderName(e.target.value);
      onChange?.('sender_name', e.target.value);
    }

    const updateToField = (e) => {
      setTo(e.target.value);
      onChange?.('to', e.target.value);
    }

    const updateSubjectField = (e) => {
      setSubject(e.target.value);
      onChange?.('subject', e.target.value);
    }

    const updateCcField = (e) => {
      setCc(e.target.value);
      onChange?.('cc', e.target.value);
    }

    const updateBccField = (e) => {
      setBcc(e.target.value);
      onChange?.('bcc', e.target.value);
    }

    const updateDescriptionField = (e) => {
      setDescription(e.target.value);
      onChange?.('from', e.target.value);
    }

    return (
        <TabContainer>
            <h1>General</h1>

            <InputWrapper>
                <Subtitle>From</Subtitle>
                <InputText 
                    placeholder="From" 
                    value={from}
                    onChange={updateFromField}
                />
            </InputWrapper>

            <InputWrapper>
                <Subtitle>Sender Name*</Subtitle>
                <InputText 
                    placeholder="Please enter your name" 
                    value={senderName}
                    displayError={senderName && senderName.length === 0}
                    validationError={"Your name is required for sending this message."}
                    onChange={updateSenderNameField}
                />
            </InputWrapper>

            <InputWrapper>
                <Subtitle>To</Subtitle>
                <InputText 
                    placeholder="To" 
                    value={to}
                    onChange={updateToField}
                />
            </InputWrapper>
            
            <InputWrapper>
                <Subtitle>Subject*</Subtitle>
                <InputText 
                    placeholder="Subject" 
                    value={subject}
                    onChange={updateSubjectField}
                />
            </InputWrapper>

            <InputWrapper>
                <Subtitle>CC</Subtitle>
                <InputText 
                    placeholder="CC" 
                    value={cc}
                    onChange={updateCcField}
                />
            </InputWrapper>

            <InputWrapper>
                <Subtitle>BCC</Subtitle>
                <InputText 
                    placeholder="BCC" 
                    value={bcc}
                    onChange={updateBccField}
                />
            </InputWrapper>

            <InputWrapper>
                <Subtitle>Description (optional)</Subtitle>
                <TextArea 
                    placeholder="Enter a description"
                    height="120px"
                    resize="none"
                    value={description}
                    onChange={updateDescriptionField}
                />
            </InputWrapper>
        </TabContainer>
    );
};

export default GeneralTab