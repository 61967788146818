import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SuccessfulModal } from "../../modals";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import {
  TemplateEditorWrapper,
  EmailEditorWrapper,
  TabsWrapper,
  Backdrop,
  TemplateEditorContainer,
  TemplateEditorFooter,
  ButtonWrapper,
} from "./TemplateEditor.styled";
import GeneralTab from "./GeneralTab";
import VariablesTab from "./VariablesTab";
import Spinner from "../../../components/common/Spinner";
import EmailEditor from "react-email-editor";
import IconTabs from "../Tabs/IconTabs/IconTabs";
import { RoundedButton } from "../../button";
import { ImagePickerModal } from "./components";
import { templateEditor } from "../../../components/app/consts";
import debounce from 'lodash/debounce';

const TemplateEditor = ({
 isOpen = true,
 isPopup = true,
 isLoading = false,
 onClose,
 onContentChange,
 onImageStock,
 isReadMode,
 initialContent,
 initialGeneralData,
 initialVariables,
 templateJson,
}) => {
const emailEditorRef = useRef(null);
const doneCallbackRef = useRef(null);

const [isOpenCustomLibraryModal, setIsOpenCustomLibraryModal] = useState(false);
const [variables, setVariables] = useState([]);
const [generalData, setGeneralData] = useState();
const [timerFunction, setTimerFunction] = useState();

useEffect(() => {
  if (initialGeneralData) {
    setGeneralData(initialGeneralData);
  }
}, [initialGeneralData]);

useEffect(() => {
  if (initialVariables) {
    setVariables(initialVariables);
  }
}, [initialVariables]);

const debouncedContentChange = () => {
  if (timerFunction) {
    timerFunction.cancel();
  }

  const currentTimerFunction = debounce(() => {
    exportHtml();
  }, 5000);
  setTimerFunction(currentTimerFunction);
}

const setTags = (variables) => {
  if (variables.length && emailEditorRef.current?.editor) {
    const variablesToSet = {};
    for (const variable of variables) {
      variablesToSet[variable.variable] = {
        name: variable.name,
        value: `{{${variable.variable}}}`,
        sample: `{{${variable.variable}}}`,
      };
    }

    emailEditorRef.current.editor.setMergeTags(variablesToSet);
  }

  setVariables(variables);
  debouncedContentChange();
};

const onChangeGeneralData = (field, value) => {
  const currentGeneralData = generalData;
  currentGeneralData[field] = value;
  setGeneralData(currentGeneralData);
  debouncedContentChange();
};

const exportHtml = () => {
  const unlayer = emailEditorRef.current?.editor;

  unlayer?.exportHtml(async (data) => {
    const { design, html } = data;

    onContentChange?.({
      design,
      html,
      variables,
      generalData,
    });
  });
};

const closeEditor = () => {
  exportHtml();
  onClose && onClose();
};

const onReady = () => {
  const unlayer = emailEditorRef.current?.editor;

  if (templateJson) {
    unlayer.loadDesign(toJS(templateJson));
  }

  unlayer.addEventListener('design:updated', debouncedContentChange);
  unlayer.addEventListener('design:updated:paste', exportHtml);

  if (initialContent) {
    try {
      const safeDesign = {
        body: {
          rows: [{
            cells: [1],
            columns: [{
              contents: [{
                type: "text",
                values: {
                  text: initialContent,
                },
              }],
            }],
          }],
          values: {
            backgroundColor: "#ffffff",
            width: "600px",
            padding: "20px",
          },
        },
      };

      unlayer.loadDesign(safeDesign);
    } catch (error) {
      unlayer.loadDesign({
        html: initialContent,
      });
    }
  }

  unlayer.registerCallback("selectImage", (data, done) => {
    doneCallbackRef.current = done;
    setIsOpenCustomLibraryModal(true);
  });

  unlayer.registerProvider("userUploads", async (params, done) => {
    const page = params.page || 1;
    const perPage = params.perPage || 20;
    const isCount = page === 1;
    const searchText = params.searchText;

    const payload = {
      count_records: isCount,
      page_size: perPage,
      page,
      search: searchText,
    };

    if (onImageStock) {
      const { stockImages, hasMore, total } = await onImageStock(payload);

      done(toJS(stockImages), {
        hasMore,
        page,
        perPage,
        total,
      });
    }
  });
};

const tabs = [
  {
    label: "General",
    icon: "gear",
    content: <GeneralTab initialData={generalData} onChange={onChangeGeneralData} />,
  },
  {
    label: "Variables",
    icon: "brackets",
    content: <VariablesTab initValues={variables} onUpdate={setTags} />,
  },
];

return (
  <>
    <ImagePickerModal
      isOpenCustomLibraryModal={isOpenCustomLibraryModal}
      doneCallbackRef={doneCallbackRef}
      setIsOpenCustomLibraryModal={setIsOpenCustomLibraryModal}
    />
    {isPopup ? (
      <Backdrop isOpen={isOpen}>
        <TemplateEditorWrapper>
          <TemplateEditorContainer>
            <TabsWrapper>
              <IconTabs tabs={tabs} />
            </TabsWrapper>
            <EmailEditorWrapper>
              {isLoading ? (
                <Spinner />
              ) : (
                <EmailEditor
                  onReady={onReady}
                  ref={emailEditorRef}
                  options={templateEditor.options}
                />
              )}
            </EmailEditorWrapper>
          </TemplateEditorContainer>
          <TemplateEditorFooter>
            <RoundedButton
              width="79px"
              kind="secondary"
              onClick={closeEditor}
            >
              Cancel
            </RoundedButton>
            {!isReadMode && (
              <ButtonWrapper>
                <RoundedButton
                  width="89px"
                  onClick={exportHtml}
                  disabled={false}
                >
                  Update
                </RoundedButton>
              </ButtonWrapper>
            )}
          </TemplateEditorFooter>
        </TemplateEditorWrapper>
      </Backdrop>
    ) : (
      <TemplateEditorContainer>
        <TabsWrapper>
          <IconTabs tabs={tabs} />
        </TabsWrapper>
        <EmailEditorWrapper>
          {isLoading ? (
            <Spinner />
          ) : (
            <EmailEditor
              onReady={onReady}
              ref={emailEditorRef}
              options={templateEditor.options}
            />
          )}
        </EmailEditorWrapper>
      </TemplateEditorContainer>
    )}
  </>
);
};

export default TemplateEditor;