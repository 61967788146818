import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';

import { getAgent, getConversationChatAssistant } from '../../../../graphql/queries';
import CustomerSelect from "../../../../components/common/CustomersSelect";
import { onChatAssistantResponse } from "../../../../graphql/subscriptions";

import {
    ChatInput,
    ChatContent,
    ConciergeContainer,
    CenteredText,
    Spinner,
} from './ConciergeChat.styled';
import _ from "lodash"
import { observer } from "mobx-react";
import { useStore } from "../../../../hooks";
import dayjs from 'dayjs'

const ConciergeChat = observer(({ isConciergeCollapsed, onClose }) => {
    const { authStore, conversationsStore } = useStore();
    const [agentIsTyping, setAgentIsTyping] = useState(false);
    const { customerId, isSuperAdmin, selectedAccount } = authStore;
    const [newMessage, setNewMessage] = useState('');
    const [showSpinner, setShowSpinner] = useState(false);
    const [isLoadingConcierge, setIsLoadingConcierge] = useState(false);
    const initialMessage = { message: "What can I assist you with today?", type: "received", time: dayjs().format('HH:mm') }
    const [messages, setMessages] = useState([initialMessage]);
    const [conciergeAgent, setConciergeAgent] = useState();
    const [agent_params, setAgent_params] = useState({
        message: "",
        campaign_id: "",
        campaign_version: "",
        agent: "",
    });
    const [attachment, setAttachment] = useState("");
    const [chatWindowHeight, setChatWindowHeight] = useState(70);
    const [conversationID, setConversationID] = useState("");
    const [isSendMessageClicked, setIsSendMessageClicked] = useState(false);
    const [isStreaming, setIsStreaming] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const textAreaRef = useRef();
    
    useEffect(() => {
        setMessages([initialMessage]);
        setAgent_params((prevParams) => ({
            ...prevParams,
            customer_id: selectedAccount.id,
        }));
        fetchConciergeAgent(selectedAccount.id);
        conversationsStore.setCustomerId(authStore.selectedAccount.id);
    }, [selectedAccount]);
    
    
    const fetchConciergeAgent = async (customer) => {
        try {
            setIsLoadingConcierge(true);
            const response = await API.graphql({
                query: getAgent,
                variables: { input: { customer_id: customer, domain: "concierge" } },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });
            const agents = JSON.parse(response.data.getAgent.body).filter(agent => agent.provider === 'openai');
            const conciergeAgent = agents[0];
            setConciergeAgent(conciergeAgent)
            setAgent_params((prevParams) => ({
                ...prevParams,
                agent: conciergeAgent?.id,
            }));
        } catch (error) {
            console.error("Error getting Agents", error);
            console.log('Error fetching agents');
        } finally {
            setIsLoadingConcierge(false);
        }
    };
    
    useLayoutEffect(() => {
        const textAreaWrapper = textAreaRef.current;
        if (textAreaWrapper) {
            const textArea = textAreaWrapper.getElementsByTagName('textarea')[0]
            
            textArea.style.height = "0px";
            const scrollHeight = textArea.scrollHeight;
            
            setChatWindowHeight(Math.max(58, 74 - (100 * scrollHeight / window.innerHeight)))
            textArea.style.height = scrollHeight + "px";
        }
    }, [newMessage]);
    
    const handleCleanThread = () => {
        setMessages([initialMessage]);
        setConversationID("");
        setAttachment("");
        setNewMessage("");
        setIsSendMessageClicked(false);
        setIsStreaming(false);
        if (subscription) {
            subscription.unsubscribe();
            setSubscription(null);
        }
    };
    
    const handleStopClick = () => {
        if (subscription && (conversationsStore.runId && conversationsStore.threadId && conversationsStore.customerId)) {
            conversationsStore.stopResponse();
            subscription.unsubscribe();
            setSubscription(null);
            setIsStreaming(false);
            setAgentIsTyping(false);
            setIsSendMessageClicked(false);
        }
    };
    
    const handleSendMessage = async () => {
        if (newMessage.trim() === "") return;
        
        setIsSendMessageClicked(true);
        setNewMessage("");
        
        const params = {
            ...agent_params,
            message: newMessage,
            attachment,
            conversation_id: conversationID
        };
        
        try {
            let openAiAnswer = null;
            let local_msgs = _.union(messages, [])
            
            let resultConversationOpenAi = null
            try {
                resultConversationOpenAi = await API.graphql({
                    query: getConversationChatAssistant,
                    variables: { input: params },
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
                if (!resultConversationOpenAi.errors?.length) {
                    setAgentIsTyping(true);
                    
                    const apiResponse = resultConversationOpenAi.data.getConversationChatAssistant;
                    
                    const parseResponseBody = (responseBody) => JSON.parse(responseBody);
                    
                    local_msgs = _.union(local_msgs, [{ message: newMessage, type: "sent", time: dayjs().format('HH:mm') }])
                    let tmpMsg = "";
                    let initialMessages = [...local_msgs];
                    let isStreaming = false;
                    setAttachment("");
                    setMessages(local_msgs);
                    
                    // Subscribe to chat assistant response
                    let assistantResponseSub = API.graphql({
                        ...graphqlOperation(onChatAssistantResponse, { id: apiResponse.id }),
                        authMode: 'AMAZON_COGNITO_USER_POOLS'
                    }).subscribe({
                        next: ({ value }) => {
                            const subApiResponse = value.data.onChatAssistantResponse;
                            const body = parseResponseBody(subApiResponse.body)
                            conversationsStore.setThreadId(body.thread_id);
                            conversationsStore.setRunId(body.run_id);
                            setIsStreaming(true);
                            openAiAnswer = body.answer;
                            setShowSpinner(false);
                            setAgentIsTyping(false);
                            setSubscription(assistantResponseSub);
                            
                            setConversationID(body.conversation_id)
                            
                            if (subApiResponse.status === 'done' || subApiResponse.status === 'guardrail') {
                                setIsStreaming(false);
                                if (!isStreaming) {
                                    const newMessages = [...initialMessages, { message: openAiAnswer, type: "received", time: dayjs().format('HH:mm') }];
                                    setMessages(newMessages)
                                    initialMessages = [...newMessages];
                                }
                                assistantResponseSub.unsubscribe();
                                setSubscription(null);
                                tmpMsg = '';
                                initialMessages = [...messages];
                                isStreaming = false;
                                setIsSendMessageClicked(false);
                            } else {
                                isStreaming = true;
                                tmpMsg += openAiAnswer
                                setMessages([...initialMessages, { message: tmpMsg, type: "received", time: dayjs().format('HH:mm') }])
                            }
                        },
                        error: (error) => {
                            console.warn(error)
                            setShowSpinner(false);
                            setAgentIsTyping(false);
                            setIsSendMessageClicked(false);
                            setIsStreaming(false);
                            local_msgs = _.union(local_msgs, [{
                                message: "Oops! Something went wrong. Please try again.",
                                type: "received",
                                time: dayjs().format('HH:mm')
                            }])
                            setMessages(local_msgs);
                        }
                    });
                } else {
                    setIsSendMessageClicked(false);
                    setIsStreaming(false);
                    setMessages([
                        ...messages,
                        {
                            message: "Oops! Something went wrong. Please try again.",
                            type: "received",
                            time: dayjs().format('HH:mm')
                        },
                    ]);
                }
            } catch (err) {
                console.log(err)
                setIsSendMessageClicked(false);
                setIsStreaming(false);
                setMessages([
                    ...messages,
                    {
                        message: "Oops! Something went wrong. Please try again.",
                        type: "received",
                        time: dayjs().format('HH:mm')
                    },
                ]);
            }
        } catch (error) {
            console.error(error);
            setIsSendMessageClicked(false);
            setIsStreaming(false);
            
            if (error.errors && error.errors[0].errorType === "Lambda:ExecutionTimeoutException") {
                setMessages([
                    ...messages,
                    {
                        message: "Sorry, I'm taking longer than expected to get your answer. I'll notify my team to teach me more about this topic. Meanwhile, let's continue with other questions you have?",
                        type: "received",
                        time: dayjs().format('HH:mm')
                    },
                ]);
            }
        }
    };
    
    const bottomRef = useRef(null);
    
    useEffect(() => {
        scrollToBottom();
    }, [messages]);
    
    const scrollToBottom = () => {
        bottomRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
        });
    };
    
    if (isConciergeCollapsed) {
        return null;
    }
    
    return (
      <>
          <ConciergeContainer data-testid="concierge-chat">
              {isLoadingConcierge ?
                <CenteredText>{'Getting concierge agent...'}</CenteredText> :
                conciergeAgent ?
                  <>
                      <ChatContent
                        selectedAgent={conciergeAgent}
                        messages={messages}
                        bottomRef={bottomRef}
                        showSpinner={showSpinner}
                        displayButtons={false}
                        width='100%'
                        agentIsTyping={agentIsTyping}
                        height={`${chatWindowHeight}vh`}
                      />
                      {showSpinner && <Spinner className="spinner" />}

                      <ChatInput
                        customerId={authStore.selectedAccount.id}
                        textAreaRef={textAreaRef}
                        onChange={(e) => setNewMessage(e.target.value)}
                        showSpinner={showSpinner}
                        newMessage={newMessage}
                        onCleanThread={handleCleanThread}
                        onKeyPress={(e, callback) => {
                            if (e.key === 'Enter' && !e.shiftKey && !showSpinner && !isSendMessageClicked) {
                                e.preventDefault();
                                handleSendMessage();
                                callback('')
                            }
                        }}
                        handleSendMessage={handleSendMessage}
                        onFileUploaded={setAttachment}
                        disableSend={isSendMessageClicked}
                        stop={isStreaming}
                        onStopClick={handleStopClick}
                      />
                  </> :
                  <CenteredText>
                      {isSuperAdmin ?
                        `Concierge agent is not created yet for the selected customer.` :
                        'Concierge agent is not created yet.'
                      }
                  </CenteredText>
              }
          </ConciergeContainer>
      </>
    )
});

export default ConciergeChat;