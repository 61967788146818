import InputText from '../TextInput/TextInput';
import {
    VariableWrapper,
    VariableContainer,
    VariableHeader,
    VariableBody,
    VariableSelects
} from './TemplateEditor.styled';
import { CustomSelect, IconButton } from '../../'
import { useCallback, useEffect, useMemo, useState } from 'react';
import { OriginVariables } from "../../../utils/enums";

const Variable = ({ data, sources, onDelete, onChange }) => {
    const [isOpened, setIsOpened] = useState(false);
    const [id, setId] = useState(0);
    const [name, setName] = useState('');
    const [variable, setVariable] = useState('');
    const [origin, setOrigin] = useState('');
    const [source, setSource] = useState('');
    const [field, setField] = useState('');
    const [value, setValue] = useState('');

    useEffect(() => {
        if (!data) return;

        const { id, name, variable, origin, source, field, value } = data;
        setId(id);
        setName(name);
        setVariable(variable);
        setOrigin(origin);
        setSource(source);
        setField(field);
        setValue(value);
    }, [data]);

    const filterSource = useMemo(() => {
        if (sources && sources.length > 0) {
            return sources.map((source) => ({ name: source.origin, value: source.origin }));
        }
        return [];
    });

    const filterFields = useMemo(() => {
        if (sources && sources.length > 0) {
            const selectedOrigin = sources.find((field) => field.origin === source );
            if (selectedOrigin) {
                return selectedOrigin.fields?.map((field) => ({ name: field.name, value: field.field, field_type: field.field_type, operators: field.operators, default_values: field.default_values }));
            }
        }
        return [];
      }, [source]);

    const onClear = () => {
        setId(0);
        setName('');
        setVariable('');
        setOrigin('');
        setSource('');
        setField('');
        setValue('');
    }

    const onChangeName = (e) => {
        setName(e.target.value);
        onChange?.('name', id, e.target.value);
    }

    const onChangeVariable = (e) => {
        setVariable(e.target.value);
        onChange?.('variable', id, e.target.value);
    }

    const onChangeValue = (e) => {
        setValue(e.target.value);
        onChange?.('value', id, e.target.value);
    }

    const onChangeOrigin = (value) => {
        setOrigin(value);
        onChange?.('origin', id, value);
    }

    const onChangeSource = (value) => {
        setSource(value);
        onChange?.('source', id, value);
    }

    const onChangeField = (value) => {
        setField(value);
        onChange?.('field', id, value);
    }

    const onDeleteVariable = useCallback(() => {
        onClear();
        onDelete?.(id);
    }, [onDelete]);

    return (
        <VariableWrapper>
            <IconButton icon={!isOpened ? 'arrowDown' : 'arrowUp'} width='12px' height='12px' onClick={() => setIsOpened(!isOpened)}/>
            <VariableContainer>
                <VariableHeader>
                    <InputText 
                        placeholder="Enter a name" 
                        width="90%" 
                        value={name}
                        onChange={onChangeName}
                    />
                    <InputText 
                        placeholder="Variable" 
                        width="90%" 
                        value={variable}
                        onChange={onChangeVariable}
                    />
                    <span><IconButton icon={'coloredTrashcan'} width={'15px'} onClick={onDeleteVariable}/></span>
                </VariableHeader>
                {isOpened && <VariableBody>
                    <div>
                        <CustomSelect 
                            placeholder="Select type" 
                            width='90%'
                            searchBar={false}
                            options={[
                                {name: 'Fixed', value: OriginVariables.FIXED},
                                {name: 'Event', value: OriginVariables.EVENT},
                            ]}
                            handleSelect={(_, value) => onChangeOrigin(value)}
                            selectedDefault={origin}
                        />
                    </div>
                    {
                        origin === OriginVariables.FIXED &&
                        (
                            <InputText
                                placeholder="Value"
                                width="90%"
                                value={value}
                                onChange={onChangeValue}
                            />
                        )
                    }
                    { origin === OriginVariables.EVENT &&
                        (
                            <VariableSelects>
                                {/* Options in the selects below are intended to be hardcoded */}
                                <CustomSelect 
                                    width='45%'
                                    placeholder='Source'
                                    searchBar={false}
                                    options={filterSource}
                                    handleSelect={(_, value) => onChangeSource(value)}
                                    selectedDefault={source}
                                />
                                <CustomSelect 
                                    width='45%'
                                    searchBar={false}
                                    placeholder='Field'
                                    options={filterFields}
                                    handleSelect={(_, value) => onChangeField(value)}
                                    selectedDefault={field}
                                />
                            </VariableSelects>
                        )
                    }
                </VariableBody>}
            </VariableContainer>
        </VariableWrapper>
    );
};

export default Variable