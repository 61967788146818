import { 
    InputWrapper, 
    TabContainer,
    ButtonContiner,
    ButtonWrapper
} from './TemplateEditor.styled';
import Variable from './Variable';
import RoundedIconButton from '../Buttons/RoundedIconButton/RoundedIconButton';
import { RoundedButton } from '../../button';
import {useEffect, useState} from "react";

const VariablesTab = ({ initValues, sources, onUpdate }) => {
    const [variables, setVariables] = useState([]);

  useEffect(() => {
    if (initValues) {
      setVariables(initValues);
    }
  }, [initValues]);

    const addVariable = () => {
        const index = variables.length;
        setVariables([
          ...variables,
          { id: index, name: '', variable: '', value: '', origin: '', source: '', field: '' }
        ]);
    }

    const onDeleteVariable = (id) => {
        setVariables(variables.filter((variable) => variable.id !== id));
    }

    const onChangeVariable = (field, id, value) => {
        const updatedVariables = variables.map((variable) => {
          if (variable.id === id) {
            return { ...variable, [field]: value };
          }
          return variable;
        });
        setVariables(updatedVariables);
    }

    const onHandleSave = () => {
        onUpdate?.(variables);
    }

    return (
        <TabContainer>
            <h1>Variable</h1>
            <ButtonContiner>
                <RoundedIconButton width='24px' height='24px' icon='circleAdd' onClick={addVariable}/>
                <ButtonWrapper>
                    <RoundedButton width="79px" onClick={onHandleSave} height='36px'>Save</RoundedButton>
                </ButtonWrapper>
            </ButtonContiner>

            <InputWrapper>
                {variables.map((data, idx) =>
                  (<Variable key={idx} data={data} sources={sources} onDelete={onDeleteVariable} onChange={onChangeVariable} />)
                )}
            </InputWrapper>
            
        </TabContainer>
    );
};

export default VariablesTab